import React from "react";

import {
    Container,
    Box,
    // Customizable Area Start
    Card,
    CardContent,
    Typography,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import RepostController, { Props } from "./RepostController";

export default class RepostList extends RepostController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container
                    maxWidth={"lg"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={webStyle.mainWrapper} data-testid="list">
                        {(this.state.repostedContent || []).map((item, index) => (
                            <Box key={index} sx={{ width: '100%' }} data-testid="post_container">
                                <Card
                                    style={{ maxWidth: 200, width: 300, padding: "10px" }}
                                    data-testid='reposted_container'
                                >
                                    <Typography
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            textAlign: "center",
                                        }}
                                    >
                                        {item.post.id}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            textAlign: "center",
                                        }}
                                    >
                                        Reposted
                                    </Typography>
                                    <p
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 100,
                                            textAlign: "left",
                                        }}
                                    >
                                        {this.getDateFormate(item.post.created_at)}
                                    </p>
                                    <CardContent style={{ maxHeight: "50px", padding: "5px" }}>
                                        <Typography style={{ fontSize: "10px" }}>
                                            {item.post.additional_note}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    style={{ maxWidth: 200, width: 300, padding: "10px", marginTop: '30px', float: "right", }}
                                    key={index}
                                    data-testid='parent_posted_container'
                                >
                                    <Typography
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            textAlign: "center",
                                        }}
                                    >
                                        {item.parent_post.id}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            textAlign: "left",
                                        }}
                                    >
                                        {item.author_user}
                                    </Typography>
                                    <p
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 100,
                                            textAlign: "left",
                                        }}
                                    >
                                        {this.getDateFormate(item.parent_post.created_at)}
                                    </p>
                                    <CardContent style={{ maxHeight: "50px", padding: "5px" }}>
                                        <Typography style={{ fontSize: "10px" }}>
                                            {item.parent_post.additional_note}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                        ))}
                    </Box>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        alignItems: "center",
        padding: "30px",
        background: "#fff",
        maxWidth: "75%",
        minWidth: '300px',
        gap: "30px",
        flexWrap: "wrap",
    },
    postStyle: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        padding: "5px",
    },
};
// Customizable Area End
